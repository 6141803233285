/* Packages */
import React from 'react'
/* Imported Components */
import { Layout } from '../components'
import VerticalImages from '../components/archives/verticalImages/verticalImages'
/* Styles */
// import styles from './archives.module.scss'
/* Component */
const Archives = () => {
  return (
    <Layout title='Archives'>
      <VerticalImages />
    </Layout>
  )
}
/* Exports */
export default Archives
