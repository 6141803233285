/* Packages */
import React, { useContext, useEffect, useState } from 'react'
import BackgroundImage from 'gatsby-background-image'
/* Imported Components */
import { Context } from '../../../context'
import Archive from '../archive/archive'
/* Styles */
import styles from './verticalImages.module.scss'
/* Component */
const VerticalImages = () => {
  const { windowSize: { device }, archives, clearSelections, setClearSelections } = useContext(Context)
  const [verticalImagesClasses, setVerticalImagesClasses] = useState(styles.verticalImages)
  const [activeArchive, setActiveArchive] = useState()
  const [archiveClasses, setArchiveClasses] = useState(styles.archive)
  const [archiveCoverClasses, setArchiveCoverClasses] = useState(styles.cover)

  useEffect(() => {
    if (clearSelections) {
      setClearSelections(false)
      setActiveArchive(undefined)
    }
  }, [clearSelections])

  useEffect(() => {
    if (activeArchive !== undefined) {
      setArchiveClasses(styles.archive + ' ' + styles.active)
      setTimeout(() => {
        setArchiveCoverClasses(styles.cover + ' ' + styles.active)
      }, 750)
    } else {
      setArchiveCoverClasses(styles.cover)
      setTimeout(() => {
        setArchiveClasses(styles.archive)
      }, 750)
    }
  }, [activeArchive])

  useEffect(() => {
    setVerticalImagesClasses(styles.verticalImages + `${device === 'phone' ? ' ' + styles.phone : ''}`)
  }, [device])

  const VerticalImage = ({ title, image, i }) => {
    const [verticalImageClasses, setVerticalImageClasses] = useState(styles.verticalImage)
    const [coverClasses, setCoverClasses] = useState(styles.cover)
    const activateArchive = () => {
      if (activeArchive === undefined || activeArchive !== i) {
        setActiveArchive(i)
      } else {
        setActiveArchive(undefined)
      }
    }

    useEffect(() => {
      setTimeout(() => {
        setVerticalImageClasses(styles.verticalImage + ' ' + styles.show)
        setTimeout(() => {
          setCoverClasses(styles.cover + ' ' + styles.hide)
          setTimeout(() => {
            setVerticalImageClasses(styles.verticalImage + ' ' + styles.show + ' ' + styles.grayscale)
          }, 250)
        }, 500)
      }, (i + 1) * 100)
    }, [archives])

    return (
      <div key={i} className={verticalImageClasses} onClick={activateArchive}>
        <BackgroundImage Tag='div' className={styles.image} fluid={image.fluid}>
          <div>
            <div className={styles.label}>
              <div className={styles.title}>
                <strong>{title}</strong>
              </div>
            </div>
            <div className={styles.overlay} />
          </div>
          <div className={coverClasses} />
        </BackgroundImage>
      </div>
    )
  }

  return (
    <section className={verticalImagesClasses}>
      {activeArchive === undefined && archives.map(({ title, image }, i) => (
        <VerticalImage key={i} title={title} image={image} i={i} />
      ))}
      <div className={archiveClasses}>
        {activeArchive !== undefined && <Archive setActiveArchive={setActiveArchive} activeArchive={activeArchive} />}
        <div className={archiveCoverClasses} />
      </div>
    </section>
  )
}
/* Exports */
export default VerticalImages
