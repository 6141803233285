/* Packages */
import React, { useContext, useEffect, useState } from 'react'
import Img from 'gatsby-image'
import ReactPlayer from 'react-player'
import Masonry from 'react-masonry-css'
/* Imported Components */
import { Context } from '../../../context'
/* Styles */
import styles from './archive.module.scss'
/* Component */
const Archive = ({ setActiveArchive, activeArchive }) => {
  // Context
  const { archives, arrowLeft, arrowRight } = useContext(Context)
  // State
  const [archive, setArchive] = useState()
  const [items, setItems] = useState([])
  const [activeItemId, setActiveItemId] = useState()
  // Functions
  const navigateToArchives = () => setActiveArchive(undefined)
  const clearActiveItemId = () => setActiveItemId(undefined)
  const previousItem = () => setActiveItemId(activeItemId === 0 ? items.length - 1 : activeItemId - 1)
  const nextItem = () => setActiveItemId(activeItemId === items.length - 1 ? 0 : activeItemId + 1)
  // Components
  const Document = ({ image, i }) => {
    const showLightbox = () => setActiveItemId(i)
    return (
      <div className={styles.image} onClick={showLightbox}>
        <Img className={styles.image} fluid={image.thumbnail.fluid} alt={image.title} />
        <div className={styles.title}>
          {image.showTitle && image.title}
        </div>
      </div>
    )
  }
  const Image = ({ image, i }) => {
    const showLightbox = () => setActiveItemId(i)
    return (
      <div className={styles.image} onClick={showLightbox}>
        <Img className={styles.image + `${archive.title === 'Photos' ? ` ${styles.border}` : ''}`} fluid={image.fluid} alt={image.title} />
        <div className={styles.title}>
          {image.showTitle && image.title}
        </div>
      </div>
    )
  }
  const Video = ({ video, i }) => {
    const showLightbox = () => setActiveItemId(i)
    return (
      <div className={styles.video} onClick={showLightbox}>
        <img className={styles.image} src={video.thumbnailHQ} alt={video.title} />
        <div className={styles.title}>
          {video.showTitle && video.title}
        </div>
      </div>
    )
  }
  // Effects
  useEffect(() => {
    if (archives.length && activeArchive !== undefined) {
      setArchive(archives[activeArchive])
    }
  }, [archives, activeArchive])
  useEffect(() => {
    if (archive) {
      if (archive.mediaType === 'Documents') {
        setItems(archive.items)
      }
      if (archive.mediaType === 'Images') {
        const images = []
        archive.items.filter(item => item.__typename === 'ContentfulArchiveImage').forEach(image => {
          images.push({
            title: image.title,
            showTitle: image.showTitle,
            file: image.image.file,
            fluid: image.image.fluid
          })
        })
        setItems(images)
      }
      if (archive.mediaType === 'Videos') {
        const videos = []
        archive.items.forEach(video => {
          videos.push({
            title: video.title,
            showTitle: video.showTitle,
            video: video.url,
            thumbnailHQ: video.url && 'https://img.youtube.com/vi/' + video.url.replace('https://www.youtube.com/watch?v=', '').replace('https://youtu.be/', '').replace('/', '') + '/hqdefault.jpg'
          })
        })
        setItems(videos)
      }
    }
  }, [archive])
  useEffect(() => {
    const keyCheck = e => {
      if (e.key === 'ArrowLeft' && activeItemId !== undefined) {
        setActiveItemId(activeItemId === 0 ? items.length - 1 : activeItemId - 1)
      }
      if (e.key === 'ArrowRight' && activeItemId !== undefined) {
        setActiveItemId(activeItemId === items.length - 1 ? 0 : activeItemId + 1)
      }
    }
    document.addEventListener('keydown', keyCheck)
    return () => document.removeEventListener('keydown', keyCheck)
  }, [activeItemId])
  // Return
  return (
    <section className={styles.section}>
      <div className={styles.closeSection}>
        <button className={styles.closeButton} onClick={navigateToArchives}>&times;</button>
      </div>
      {archive && <div className={styles.title}>{archive.title}</div>}
      {items.length && archive.mediaType === 'Documents' ? (
        <Masonry breakpointCols={{ default: 4, 1320: 3, 768: 2 }} className={styles.content} columnClassName={styles.column}>
          {items.map((image, i) => <Document key={i} image={image} i={i} />)}
        </Masonry>
      ) : null}
      {items.length && archive.mediaType === 'Images' ? (
        <Masonry breakpointCols={{ default: 5, 1320: 4, 1000: 3, 768: 2 }} className={styles.content} columnClassName={styles.column}>
          {items.map((image, i) => <Image key={i} image={image} i={i} />)}
        </Masonry>
      ) : null}
      {items.length && archive.mediaType === 'Videos' ? (
        <Masonry breakpointCols={{ default: 4, 1320: 3, 1000: 2, 768: 1 }} className={styles.content} columnClassName={styles.column}>
          {items.map((video, i) => <Video key={i} video={video} i={i} />)}
        </Masonry>
      ) : null}
      {activeItemId !== undefined ? (
        <section className={styles.lightbox}>
          <div className={styles.left}>
            <img className={styles.arrowLeft} onClick={previousItem} src={arrowLeft} alt='Previous Item' />
          </div>
          <div className={styles.container}>
            {items.length && archive.mediaType === 'Documents' ? (
              <a className={styles.link} href={items[activeItemId].document.file.url} target='_blank' rel='noopener noreferrer'><div style={{ background: `url(${items[activeItemId].thumbnail.file.url}) no-repeat center / contain` }} className={styles.image} /></a>
            ) : null}
            {items.length && archive.mediaType === 'Images' ? <div style={{ background: `url(${items[activeItemId].file.url}) no-repeat center / contain` }} className={styles.image} /> : null}
            {items.length && archive.mediaType === 'Videos' && items[activeItemId].video ? <div className={styles.videoWrapper}><ReactPlayer id='videoContainer' width='100%' height='100%' className={styles.video} url={items[activeItemId].video} /></div> : null}
          </div>
          <div className={styles.right}>
            <div className={styles.closeSection}>
              <button className={styles.closeButton} onClick={clearActiveItemId}>&times;</button>
            </div>
            <img className={styles.arrowRight} onClick={nextItem} src={arrowRight} alt='Next Item' />
          </div>
        </section>
      ) : null}
    </section>
  )
}
/* Exports */
export default Archive
